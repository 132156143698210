import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { CheckDuplicate } from 'components/CheckDuplicate';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import { Uploader } from 'components/Uploader';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const UtilisateursEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		nom: yup.string().nullable().label("Nom"),
		prenom: yup.string().nullable().label("Prenom"),
		pesudo: yup.string().required().label("Pesudo"),
		mobile: yup.string().nullable().label("Mobile"),
		avatar: yup.string().nullable().label("Avatar"),
		user_role_id: yup.string().nullable().label("User Role Id")
	});
	// form default values
	const formDefaultValues = {
		nom: '', 
		prenom: '', 
		pesudo: '', 
		mobile: '', 
		avatar: '', 
		user_role_id: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/utilisateurs`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="UtilisateursEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modifier"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="sm:col-6 col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nom 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nom"  onChange={formik.handleChange}  value={formik.values.nom}   label="Nom" type="text" placeholder="Entrer Nom"        className={inputClassName(formik?.errors?.nom)} />
                                                <ErrorMessage name="nom" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:col-6 col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prenom 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prenom"  onChange={formik.handleChange}  value={formik.values.prenom}   label="Prenom" type="text" placeholder="Entrer Prenom"        className={inputClassName(formik?.errors?.prenom)} />
                                                <ErrorMessage name="prenom" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Pesudo *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <CheckDuplicate value={formik.values.pesudo} apiPath="components_data/utilisateurs_pesudo_exist">
                                                { (checker) => 
                                                <>
                                                <InputText name="pesudo" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.pesudo}   label="Pesudo" type="text" placeholder="Entrer Pesudo"        className={inputClassName(formik?.errors?.pesudo)} />
                                                <ErrorMessage name="pesudo" component="span" className="p-error" />
                                                {(!checker.loading && checker.exist) && <small className="p-error">Indisponible</small>}
                                                {checker.loading && <small className="text-500">Checking...</small> }
                                                </>
                                                }
                                                </CheckDuplicate>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Mobile 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="mobile"  onChange={formik.handleChange}  value={formik.values.mobile}   label="Mobile" type="text" placeholder="Entrer Mobile"        className={inputClassName(formik?.errors?.mobile)} />
                                                <ErrorMessage name="mobile" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Avatar 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div className={inputClassName(formik?.errors?.avatar)}>
                                                    <Uploader name="avatar" showUploadedFiles value={formik.values.avatar} uploadPath="fileuploader/upload/avatar" onChange={(paths) => formik.setFieldValue('avatar', paths)} fileLimit={1} maxFileSize={3} accept=".jpg,.png,.gif,.jpeg" multiple={false} label="Choisissez des fichiers ou déposez des fichiers ici" onUploadError={(errMsg) => app.flashMsg('Upload error', errMsg, 'error')} />
                                                </div>
                                                <ErrorMessage name="avatar" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                User Role Id 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/role_id_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="user_role_id"     optionLabel="label" optionValue="value" value={formik.values.user_role_id} onChange={formik.handleChange} options={response} label="User Role Id"  placeholder="Sélectionnez une valeur"  className={inputClassName(formik?.errors?.user_role_id)}   />
                                                    <ErrorMessage name="user_role_id" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Editer" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
UtilisateursEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'utilisateurs',
	apiPath: 'utilisateurs/edit',
	routeName: 'utilisateursedit',
	submitButtonLabel: "Editer",
	formValidationError: "Le formulaire est invalide",
	formValidationMsg: "Veuillez remplir le formulaire",
	msgTitle: "Mettre à jour l'enregistrement",
	msgAfterSave: "Élément modifié avec succès",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default UtilisateursEditPage;
