import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const CharbonEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		prix_base: yup.number().required().label("Prix Base"),
		prix_min: yup.number().nullable().label("Prix Min"),
		prix_moy: yup.number().nullable().label("Prix Moy"),
		prix_max: yup.number().nullable().label("Prix Max"),
		date: yup.string().nullable().label("Date")
	});
	// form default values
	const formDefaultValues = {
		prix_base: '', 
		prix_min: '', 
		prix_moy: '', 
		prix_max: '', 
		date: new Date(), 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/charbon`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="CharbonEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modifier"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Base *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_base"  onChange={formik.handleChange}  value={formik.values.prix_base}   label="Prix Base" type="number" placeholder="Entrer Prix Base"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_base)} />
                                                <ErrorMessage name="prix_base" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Min 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_min"  onChange={formik.handleChange}  value={formik.values.prix_min}   label="Prix Min" type="number" placeholder="Entrer Prix Min"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_min)} />
                                                <ErrorMessage name="prix_min" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Moy 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_moy"  onChange={formik.handleChange}  value={formik.values.prix_moy}   label="Prix Moy" type="number" placeholder="Entrer Prix Moy"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_moy)} />
                                                <ErrorMessage name="prix_moy" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Max 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_max"  onChange={formik.handleChange}  value={formik.values.prix_max}   label="Prix Max" type="number" placeholder="Entrer Prix Max"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_max)} />
                                                <ErrorMessage name="prix_max" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Date 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="date" showButtonBar className={inputClassName(formik?.errors?.date)} dateFormat="yy-mm-dd" value={formik.values.date} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="date" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Editer" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
CharbonEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'charbon',
	apiPath: 'charbon/edit',
	routeName: 'charbonedit',
	submitButtonLabel: "Editer",
	formValidationError: "Le formulaire est invalide",
	formValidationMsg: "Veuillez remplir le formulaire",
	msgTitle: "Mettre à jour l'enregistrement",
	msgAfterSave: "Élément modifié avec succès",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default CharbonEditPage;
