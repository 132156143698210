import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import ArticlesList from 'pages/articles/List';
import ArticlesView from 'pages/articles/View';
import ArticlesAdd from 'pages/articles/Add';
import ArticlesEdit from 'pages/articles/Edit';
import CharbonList from 'pages/charbon/List';
import CharbonView from 'pages/charbon/View';
import CharbonAdd from 'pages/charbon/Add';
import CharbonEdit from 'pages/charbon/Edit';
import Co2List from 'pages/co2/List';
import Co2View from 'pages/co2/View';
import Co2Add from 'pages/co2/Add';
import Co2Edit from 'pages/co2/Edit';
import FuturelecbaseList from 'pages/futurelecbase/List';
import FuturelecbaseView from 'pages/futurelecbase/View';
import FuturelecbaseAdd from 'pages/futurelecbase/Add';
import FuturelecbaseEdit from 'pages/futurelecbase/Edit';
import FuturelecpeakList from 'pages/futurelecpeak/List';
import FuturelecpeakView from 'pages/futurelecpeak/View';
import FuturelecpeakAdd from 'pages/futurelecpeak/Add';
import FuturelecpeakEdit from 'pages/futurelecpeak/Edit';
import FuturgazbaseList from 'pages/futurgazbase/List';
import FuturgazbaseView from 'pages/futurgazbase/View';
import FuturgazbaseAdd from 'pages/futurgazbase/Add';
import FuturgazbaseEdit from 'pages/futurgazbase/Edit';
import GazbaseList from 'pages/gazbase/List';
import GazbaseView from 'pages/gazbase/View';
import GazbaseAdd from 'pages/gazbase/Add';
import GazbaseEdit from 'pages/gazbase/Edit';
import NotificationsList from 'pages/notifications/List';
import NotificationsView from 'pages/notifications/View';
import NotificationsAdd from 'pages/notifications/Add';
import NotificationsEdit from 'pages/notifications/Edit';
import NulcaireList from 'pages/nulcaire/List';
import NulcaireView from 'pages/nulcaire/View';
import NulcaireAdd from 'pages/nulcaire/Add';
import NulcaireEdit from 'pages/nulcaire/Edit';
import PermissionsList from 'pages/permissions/List';
import PermissionsView from 'pages/permissions/View';
import PermissionsAdd from 'pages/permissions/Add';
import PermissionsEdit from 'pages/permissions/Edit';
import PetroleList from 'pages/petrole/List';
import PetroleView from 'pages/petrole/View';
import PetroleAdd from 'pages/petrole/Add';
import PetroleEdit from 'pages/petrole/Edit';
import RapportsList from 'pages/rapports/List';
import RapportsView from 'pages/rapports/View';
import RapportsAdd from 'pages/rapports/Add';
import RapportsEdit from 'pages/rapports/Edit';
import RolesList from 'pages/roles/List';
import RolesView from 'pages/roles/View';
import RolesAdd from 'pages/roles/Add';
import RolesEdit from 'pages/roles/Edit';
import SenariosList from 'pages/senarios/List';
import SenariosView from 'pages/senarios/View';
import SenariosAdd from 'pages/senarios/Add';
import SenariosEdit from 'pages/senarios/Edit';
import SpotelectriciteList from 'pages/spotelectricite/List';
import SpotelectriciteView from 'pages/spotelectricite/View';
import SpotelectriciteAdd from 'pages/spotelectricite/Add';
import SpotelectriciteEdit from 'pages/spotelectricite/Edit';
import SpotgazList from 'pages/spotgaz/List';
import SpotgazView from 'pages/spotgaz/View';
import SpotgazAdd from 'pages/spotgaz/Add';
import SpotgazEdit from 'pages/spotgaz/Edit';
import TarifsList from 'pages/tarifs/List';
import TarifsView from 'pages/tarifs/View';
import TarifsAdd from 'pages/tarifs/Add';
import TarifsEdit from 'pages/tarifs/Edit';
import UtilisateursList from 'pages/utilisateurs/List';
import UtilisateursView from 'pages/utilisateurs/View';
import UtilisateursAdd from 'pages/utilisateurs/Add';
import UtilisateursEdit from 'pages/utilisateurs/Edit';
import NucleairePage from 'pages/custom/NucleairePage';
import EmissionsPage from 'pages/custom/EmissionsPage';
import ConsommationPage from 'pages/custom/ConsommationPage';
import UserPage from 'pages/home/UserPage';
import AdminPage from 'pages/home/AdminPage';
import ClientPage from 'pages/home/ClientPage';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	function UserRoleHomePage(){
		const userRole = (auth.userRole || '').toLowerCase();
		switch(userRole){
			case 'user':
				return <UserPage />
			case 'admin':
				return <AdminPage />
			case 'client':
				return <ClientPage />
			default:
				return <HomePage />;
		}
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<UserRoleHomePage />} />
				

				{/* articles pages routes */}
				<Route path="/articles" element={<ArticlesList />} />
				<Route path="/articles/:fieldName/:fieldValue" element={<ArticlesList />} />
				<Route path="/articles/index/:fieldName/:fieldValue" element={<ArticlesList />} />
				<Route path="/articles/view/:pageid" element={<ArticlesView />} />
				<Route path="/articles/add" element={<ArticlesAdd />} />
				<Route path="/articles/edit/:pageid" element={<ArticlesEdit />} />

				{/* charbon pages routes */}
				<Route path="/charbon" element={<CharbonList />} />
				<Route path="/charbon/:fieldName/:fieldValue" element={<CharbonList />} />
				<Route path="/charbon/index/:fieldName/:fieldValue" element={<CharbonList />} />
				<Route path="/charbon/view/:pageid" element={<CharbonView />} />
				<Route path="/charbon/add" element={<CharbonAdd />} />
				<Route path="/charbon/edit/:pageid" element={<CharbonEdit />} />

				{/* co2 pages routes */}
				<Route path="/co2" element={<Co2List />} />
				<Route path="/co2/:fieldName/:fieldValue" element={<Co2List />} />
				<Route path="/co2/index/:fieldName/:fieldValue" element={<Co2List />} />
				<Route path="/co2/view/:pageid" element={<Co2View />} />
				<Route path="/co2/add" element={<Co2Add />} />
				<Route path="/co2/edit/:pageid" element={<Co2Edit />} />

				{/* futurelecbase pages routes */}
				<Route path="/futurelecbase" element={<FuturelecbaseList />} />
				<Route path="/futurelecbase/:fieldName/:fieldValue" element={<FuturelecbaseList />} />
				<Route path="/futurelecbase/index/:fieldName/:fieldValue" element={<FuturelecbaseList />} />
				<Route path="/futurelecbase/view/:pageid" element={<FuturelecbaseView />} />
				<Route path="/futurelecbase/add" element={<FuturelecbaseAdd />} />
				<Route path="/futurelecbase/edit/:pageid" element={<FuturelecbaseEdit />} />

				{/* futurelecpeak pages routes */}
				<Route path="/futurelecpeak" element={<FuturelecpeakList />} />
				<Route path="/futurelecpeak/:fieldName/:fieldValue" element={<FuturelecpeakList />} />
				<Route path="/futurelecpeak/index/:fieldName/:fieldValue" element={<FuturelecpeakList />} />
				<Route path="/futurelecpeak/view/:pageid" element={<FuturelecpeakView />} />
				<Route path="/futurelecpeak/add" element={<FuturelecpeakAdd />} />
				<Route path="/futurelecpeak/edit/:pageid" element={<FuturelecpeakEdit />} />

				{/* futurgazbase pages routes */}
				<Route path="/futurgazbase" element={<FuturgazbaseList />} />
				<Route path="/futurgazbase/:fieldName/:fieldValue" element={<FuturgazbaseList />} />
				<Route path="/futurgazbase/index/:fieldName/:fieldValue" element={<FuturgazbaseList />} />
				<Route path="/futurgazbase/view/:pageid" element={<FuturgazbaseView />} />
				<Route path="/futurgazbase/add" element={<FuturgazbaseAdd />} />
				<Route path="/futurgazbase/edit/:pageid" element={<FuturgazbaseEdit />} />

				{/* gazbase pages routes */}
				<Route path="/gazbase" element={<GazbaseList />} />
				<Route path="/gazbase/:fieldName/:fieldValue" element={<GazbaseList />} />
				<Route path="/gazbase/index/:fieldName/:fieldValue" element={<GazbaseList />} />
				<Route path="/gazbase/view/:pageid" element={<GazbaseView />} />
				<Route path="/gazbase/add" element={<GazbaseAdd />} />
				<Route path="/gazbase/edit/:pageid" element={<GazbaseEdit />} />

				{/* notifications pages routes */}
				<Route path="/notifications" element={<NotificationsList />} />
				<Route path="/notifications/:fieldName/:fieldValue" element={<NotificationsList />} />
				<Route path="/notifications/index/:fieldName/:fieldValue" element={<NotificationsList />} />
				<Route path="/notifications/view/:pageid" element={<NotificationsView />} />
				<Route path="/notifications/add" element={<NotificationsAdd />} />
				<Route path="/notifications/edit/:pageid" element={<NotificationsEdit />} />

				{/* nulcaire pages routes */}
				<Route path="/nulcaire" element={<NulcaireList />} />
				<Route path="/nulcaire/:fieldName/:fieldValue" element={<NulcaireList />} />
				<Route path="/nulcaire/index/:fieldName/:fieldValue" element={<NulcaireList />} />
				<Route path="/nulcaire/view/:pageid" element={<NulcaireView />} />
				<Route path="/nulcaire/add" element={<NulcaireAdd />} />
				<Route path="/nulcaire/edit/:pageid" element={<NulcaireEdit />} />

				{/* permissions pages routes */}
				<Route path="/permissions" element={<PermissionsList />} />
				<Route path="/permissions/:fieldName/:fieldValue" element={<PermissionsList />} />
				<Route path="/permissions/index/:fieldName/:fieldValue" element={<PermissionsList />} />
				<Route path="/permissions/view/:pageid" element={<PermissionsView />} />
				<Route path="/permissions/add" element={<PermissionsAdd />} />
				<Route path="/permissions/edit/:pageid" element={<PermissionsEdit />} />

				{/* petrole pages routes */}
				<Route path="/petrole" element={<PetroleList />} />
				<Route path="/petrole/:fieldName/:fieldValue" element={<PetroleList />} />
				<Route path="/petrole/index/:fieldName/:fieldValue" element={<PetroleList />} />
				<Route path="/petrole/view/:pageid" element={<PetroleView />} />
				<Route path="/petrole/add" element={<PetroleAdd />} />
				<Route path="/petrole/edit/:pageid" element={<PetroleEdit />} />

				{/* rapports pages routes */}
				<Route path="/rapports" element={<RapportsList />} />
				<Route path="/rapports/:fieldName/:fieldValue" element={<RapportsList />} />
				<Route path="/rapports/index/:fieldName/:fieldValue" element={<RapportsList />} />
				<Route path="/rapports/view/:pageid" element={<RapportsView />} />
				<Route path="/rapports/add" element={<RapportsAdd />} />
				<Route path="/rapports/edit/:pageid" element={<RapportsEdit />} />

				{/* roles pages routes */}
				<Route path="/roles" element={<RolesList />} />
				<Route path="/roles/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/index/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/view/:pageid" element={<RolesView />} />
				<Route path="/roles/add" element={<RolesAdd />} />
				<Route path="/roles/edit/:pageid" element={<RolesEdit />} />

				{/* senarios pages routes */}
				<Route path="/senarios" element={<SenariosList />} />
				<Route path="/senarios/:fieldName/:fieldValue" element={<SenariosList />} />
				<Route path="/senarios/index/:fieldName/:fieldValue" element={<SenariosList />} />
				<Route path="/senarios/view/:pageid" element={<SenariosView />} />
				<Route path="/senarios/add" element={<SenariosAdd />} />
				<Route path="/senarios/edit/:pageid" element={<SenariosEdit />} />

				{/* spotelectricite pages routes */}
				<Route path="/spotelectricite" element={<SpotelectriciteList />} />
				<Route path="/spotelectricite/:fieldName/:fieldValue" element={<SpotelectriciteList />} />
				<Route path="/spotelectricite/index/:fieldName/:fieldValue" element={<SpotelectriciteList />} />
				<Route path="/spotelectricite/view/:pageid" element={<SpotelectriciteView />} />
				<Route path="/spotelectricite/add" element={<SpotelectriciteAdd />} />
				<Route path="/spotelectricite/edit/:pageid" element={<SpotelectriciteEdit />} />

				{/* spotgaz pages routes */}
				<Route path="/spotgaz" element={<SpotgazList />} />
				<Route path="/spotgaz/:fieldName/:fieldValue" element={<SpotgazList />} />
				<Route path="/spotgaz/index/:fieldName/:fieldValue" element={<SpotgazList />} />
				<Route path="/spotgaz/view/:pageid" element={<SpotgazView />} />
				<Route path="/spotgaz/add" element={<SpotgazAdd />} />
				<Route path="/spotgaz/edit/:pageid" element={<SpotgazEdit />} />

				{/* tarifs pages routes */}
				<Route path="/tarifs" element={<TarifsList />} />
				<Route path="/tarifs/:fieldName/:fieldValue" element={<TarifsList />} />
				<Route path="/tarifs/index/:fieldName/:fieldValue" element={<TarifsList />} />
				<Route path="/tarifs/view/:pageid" element={<TarifsView />} />
				<Route path="/tarifs/add" element={<TarifsAdd />} />
				<Route path="/tarifs/edit/:pageid" element={<TarifsEdit />} />

				{/* utilisateurs pages routes */}
				<Route path="/utilisateurs" element={<UtilisateursList />} />
				<Route path="/utilisateurs/:fieldName/:fieldValue" element={<UtilisateursList />} />
				<Route path="/utilisateurs/index/:fieldName/:fieldValue" element={<UtilisateursList />} />
				<Route path="/utilisateurs/view/:pageid" element={<UtilisateursView />} />
				<Route path="/utilisateurs/add" element={<UtilisateursAdd />} />
				<Route path="/utilisateurs/edit/:pageid" element={<UtilisateursEdit />} />
				<Route path="/nucleaire" element={<NucleairePage />} />
				<Route path="/emissions" element={<EmissionsPage />} />
				<Route path="/consommation" element={<ConsommationPage />} />
				<Route path="/user" element={<UserPage />} />
				<Route path="/admin" element={<AdminPage />} />
				<Route path="/client" element={<ClientPage />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
