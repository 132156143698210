import { useState } from 'react';
import { DataSource } from 'components/DataSource';
import useApp from 'hooks/useApp';

export default function EmissionsPage() {
		const app = useApp();
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="EmissionsPage" className="main-page">
<section className="page-section q-pa-md" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col-sm-4 col-md-4 col-12 comp-grid" >
                <div className="">
                    <DataSource   apiPath="components_data/index_data_component" firstRecord={true} showLoading={false}>
                        {
                        ({ response, loading, reload }) => 
                        <>
                        <div >
                            <>
                            <div className="fixed w-full py-6 surface-0 " style={{ top: '3.75rem' }}>
                                <div className="flex items-center">
                                    <div className="w-full text-green-400 font-bold   px-2 text-lg ">
                                        Émissions de CO2 &amp; exports 
                                    </div>
                                </div>
                            </div>
                            <div className="surface-0 w-full min-h-screen mt-3">
                                <div className="w-full min-h-screen">
                                    <iframe
                                    src="https://app.electricitymaps.com/zone/FR"
                                    style={{ width: '100%', height: '100vh' }}
                                    frameBorder="0"
                                    referrerPolicy="no-referrer"
                                    title="Electricity Maps"
                                    ></iframe>
                                </div>
                            </div>
                            </>
                        </div>
                        </>
                        }
                    </DataSource>
                </div>
            </div>
        </div>
    </div>
</section>
		</main>
	);
}
