import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import NotificationsEditPage from 'pages/notifications/Edit';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';

import useViewPage from 'hooks/useViewPage';
const NotificationsViewPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const utils = useUtils();
	const pageController = useViewPage(props);
	const { item, pageReady, loading, apiRequestError, deleteItem } = pageController;
	function ActionButton(data){
		const items = [
		{
			label: "Edit",
			command: (event) => { app.openPageDialog(<NotificationsEditPage isSubPage apiPath={`/notifications/edit/${data.id}`} />, {closeBtn: true }) },
			icon: "pi pi-pencil",
			visible: () => auth.canView('notifications/edit')
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash",
			visible: () => auth.canView('notifications/delete')
		}
	]
	.filter((item) => {
		if(item.visible){
			return item.visible()
		}
		return true;
	});
		return (<Menubar className="p-0 " model={items} />);
	}
	function PageFooter() {
		if (props.showFooter) {
			return (
				<div className="flex justify-content-between">
	<div className="flex justify-content-start">
	{ActionButton(item)}
	</div>
				</div>
			);
		}
	}
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	if(pageReady){
		return (
			<div>
<main id="NotificationsViewPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Vue Notification"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="col comp-grid" >
                    <div >
                        {/*PageComponentStart*/}
                        <div className="mb-3 grid ">
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Id</div>
                                        <div className="font-bold">{ item.id }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Nom Alerte</div>
                                        <div className="font-bold">{ item.nom_alerte }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Type Alerte</div>
                                        <div className="font-bold">{item.type_alerte && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.type_alerte)} tooltip={utils.humanDatetime(item.type_alerte)} />}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Max Seuil Opportunite</div>
                                        <div className="font-bold">{item.max_seuil_opportunite && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.max_seuil_opportunite)} tooltip={utils.humanDatetime(item.max_seuil_opportunite)} />}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Min Seuil Opportunite</div>
                                        <div className="font-bold">{item.min_seuil_opportunite && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.min_seuil_opportunite)} tooltip={utils.humanDatetime(item.min_seuil_opportunite)} />}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Date Debut</div>
                                        <div className="font-bold">{item.date_debut && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.date_debut)} tooltip={utils.humanDatetime(item.date_debut)} />}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Date Fin</div>
                                        <div className="font-bold">{item.date_fin && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.date_fin)} tooltip={utils.humanDatetime(item.date_fin)} />}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Siret</div>
                                        <div className="font-bold">{item.siret && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.siret)} tooltip={utils.humanDatetime(item.siret)} />}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Viewer</div>
                                        <div className="font-bold">{ item.viewer }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Note</div>
                                        <div className="font-bold">{ item.note }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Email</div>
                                        <div className="font-bold">{ item.email }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Tel</div>
                                        <div className="font-bold">{item.tel && <Button className="p-0 p-button-plain p-button-text" label={utils.relativeDate(item.tel)} tooltip={utils.humanDatetime(item.tel)} />}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Statut</div>
                                        <div className="font-bold">
                                            <div>
                                                {item.statut === 'true' ? (
                                                <Button icon="pi pi-check-circle" className="p-button-sm p-button-success" tooltip={item.statut} />
                                                ) : (
                                                <Button icon="pi pi-ban" className="p-button-sm p-button-secondary" tooltip={item.statut} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*PageComponentEnd*/}
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
				<PageFooter />
			</div>
		);
	}
}
NotificationsViewPage.defaultProps = {
	id: null,
	primaryKey: 'id',
	pageName: 'notifications',
	apiPath: 'notifications/view',
	routeName: 'notificationsview',
	msgBeforeDelete: "Êtes-vous sûre  de bien vouloir supprimer cet élément ?",
	msgTitle: "Supprimer l'enregistrement",
	msgAfterDelete: "Élément supprimé avec succès",
	showHeader: true,
	showFooter: true,
	exportButton: true,
	isSubPage: false,
}
export default NotificationsViewPage;
