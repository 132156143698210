import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { CanView } from 'components/Can';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ExportPageData } from 'components/ExportPageData';
import { FilterTags } from 'components/FilterTags';
import { ImportPageData } from 'components/ImportPageData';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';

import useListPage from 'hooks/useListPage';
import MasterDetailPages from './MasterDetailPages';
const RolesListPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const utils = useUtils();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, apiUrl, currentRecord, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, setCurrentRecord, pagination } = pageController;
	const { filters, setFilterValue } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, currentPage, limit, onPageChange } =  pagination;
	function ActionButton(data){
		const items = [
		{
			label: "Details",
			command: (event) => { app.navigate(`/roles/view/${data.role_id}`) },
			icon: "pi pi-eye",
			visible: () => auth.canView('roles/view')
		},
		{
			label: "Editer",
			command: (event) => { app.navigate(`/roles/edit/${data.role_id}`) },
			icon: "pi pi-pencil",
			visible: () => auth.canView('roles/edit')
		},
		{
			label: "Supprimer",
			command: (event) => { deleteItem(data.role_id) },
			icon: "pi pi-trash",
			visible: () => auth.canView('roles/delete')
		}
	]
	.filter((item) => {
		if(item.visible){
			return item.visible()
		}
		return true;
	});
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function RoleIdTemplate(data){
		if(data){
			return (
				<Link to={`/roles/view/${data.role_id}`}> { data.role_id }</Link>
			);
		}
	}
	function MasterDetailBtnTemplate(data){
		if(data){
			return (
				<><Button className="p-button-text" onClick={()=>setCurrentRecord(data)} icon="pi pi-caret-down" label="" /></>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Chargement...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					Aucun enregistrement est trouvé
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Supprimer sélectionnée"/>
				</div>
			)
		}
	}
	function ExportData() {
		if (props.exportData && records.length) {
			const downloadFileName = `${utils.dateNow()}-roles`;
			return (
				<div className="m-2">
					<ExportPageData  pageUrl={apiUrl} downloadFileName={downloadFileName} butonLabel="Export" tooltip="Exportation" buttonIcon="pi pi-print" />
				</div>
			);
		}
	}
	function ImportData() {
		if (props.importData) {
			return (
				<div className="m-2">
					<ImportPageData label="Sélectionnez le fichier à importer" uploadPath="roles/importdata" buttonIcon="pi pi-folder" buttonLabel="Import Data" onImportCompleted={(response) => {app.flashMsg('Import Data', response, 'success')}} />
				</div>
			);
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Page <b>{ options.currentPage } sur { options.totalPages }</b></span>
						<span className="text-sm text-gray-500 px-2">Records <b>{ recordsPosition } sur { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  rowsPerPageOptions={[5, 10, 20, 30, 50, 100, 200, 500, 1000]}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
	<CanView pagePath="roles/delete">
		<MultiDelete />
	</CanView>
				<ExportData />
	<CanView pagePath="roles/importdata">
		<ImportData />
	</CanView>
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="RolesListPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col " >
                    <Title title="Roles"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
                <div className="col-fixed " >
                    <CanView pagePath="">
                        <Link to={`/roles/add`}>
                            <Button label="Ajouter un nouveau" icon="pi pi-plus" type="button" className="p-button w-full bg-primary "  />
                            </Link>
                        </CanView>
                    </div>
                    <div className="col-12 md:col-3 " >
                        <span className="p-input-icon-left w-full">
                        <i className="pi pi-search" />
                        <InputText placeholder="Chercher" className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                        </span>
                    </div>
                </div>
            </div>
        </section>
        }
        <section className="page-section " >
            <div className="container-fluid">
                <div className="grid ">
                    <div className="col comp-grid" >
                        <FilterTags filterController={filterController} />
                        <div >
                            <PageBreadcrumbs />
                            <div className="grid ">
                                <div className="col">
                                    <div className="page-records">
                                        <DataTable 
                                            lazy={true} 
                                            loading={loading} 
                                            selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                            value={records} 
                                            dataKey="role_id" 
                                            sortField={sortBy} 
                                            sortOrder={sortOrder} 
                                            onSort={onSort}
                                            className=" p-datatable-sm" 
                                            stripedRows={true}
                                            showGridlines={false} 
                                            rowHover={true} 
                                            responsiveLayout="stack" 
                                            emptyMessage={<EmptyRecordMessage />} 
                                            >
                                            {/*PageComponentStart*/}
                                            <Column selectionMode="multiple" headerStyle={{width: '2rem'}}></Column>
                                            <Column headerStyle={{width: '3rem'}} field=""  body={MasterDetailBtnTemplate}  ></Column>
                                            <Column  field="role_id" header="Role Id" body={RoleIdTemplate} sortable={true} ></Column>
                                            <Column  field="role_name" header="Role Name"  sortable={true} ></Column>
                                            <Column headerStyle={{width: '2rem'}} headerClass="text-center" body={ActionButton}></Column>
                                            {/*PageComponentEnd*/}
                                        </DataTable>
                                    </div>
                                    <PageFooter />
                                </div>
                                {
                                (currentRecord && !props.isSubPage) && 
                                <div className="col-12">
                                    <div className="card p-0">
                                        <MasterDetailPages masterRecord={currentRecord} scrollIntoView={true} />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
	);
}
RolesListPage.defaultProps = {
	primaryKey: 'role_id',
	pageName: 'roles',
	apiPath: 'roles/index',
	routeName: 'roleslist',
	msgBeforeDelete: "Êtes-vous sûre  de bien vouloir supprimer cet élément ?",
	msgTitle: "Supprimer l'enregistrement",
	msgAfterDelete: "Élément supprimé avec succès",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: true,
	importData: true,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default RolesListPage;
