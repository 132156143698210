import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const SpotelectriciteAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		prix_baseload: yup.number().nullable().label("Prix Baseload"),
		prix_base_max: yup.number().nullable().label("Prix Base Max"),
		prix_base_min: yup.number().nullable().label("Prix Base Min"),
		prix_base_moy: yup.number().nullable().label("Prix Base Moy"),
		prix_peakload: yup.number().nullable().label("Prix Peakload"),
		prix_peak_max: yup.number().nullable().label("Prix Peak Max"),
		prix_peak_min: yup.number().nullable().label("Prix Peak Min"),
		prix_peak_moy: yup.number().nullable().label("Prix Peak Moy"),
		date: yup.string().required().label("Date")
	});
	
	//form default values
	const formDefaultValues = {
		prix_baseload: "0", 
		prix_base_max: "0", 
		prix_base_min: "0", 
		prix_base_moy: "0", 
		prix_peakload: "0", 
		prix_peak_max: "0", 
		prix_peak_min: "0", 
		prix_peak_moy: "0", 
		date: new Date(), 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/spotelectricite`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="SpotelectriciteAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Ajouter un nouveau"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Baseload 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_baseload"  onChange={formik.handleChange}  value={formik.values.prix_baseload}   label="Prix Baseload" type="number" placeholder="Entrer Prix Baseload"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_baseload)} />
                                                <ErrorMessage name="prix_baseload" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Base Max 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_base_max"  onChange={formik.handleChange}  value={formik.values.prix_base_max}   label="Prix Base Max" type="number" placeholder="Entrer Prix Base Max"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_base_max)} />
                                                <ErrorMessage name="prix_base_max" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Base Min 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_base_min"  onChange={formik.handleChange}  value={formik.values.prix_base_min}   label="Prix Base Min" type="number" placeholder="Entrer Prix Base Min"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_base_min)} />
                                                <ErrorMessage name="prix_base_min" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Base Moy 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_base_moy"  onChange={formik.handleChange}  value={formik.values.prix_base_moy}   label="Prix Base Moy" type="number" placeholder="Entrer Prix Base Moy"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_base_moy)} />
                                                <ErrorMessage name="prix_base_moy" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Peakload 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_peakload"  onChange={formik.handleChange}  value={formik.values.prix_peakload}   label="Prix Peakload" type="number" placeholder="Entrer Prix Peakload"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_peakload)} />
                                                <ErrorMessage name="prix_peakload" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Peak Max 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_peak_max"  onChange={formik.handleChange}  value={formik.values.prix_peak_max}   label="Prix Peak Max" type="number" placeholder="Entrer Prix Peak Max"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_peak_max)} />
                                                <ErrorMessage name="prix_peak_max" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Peak Min 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_peak_min"  onChange={formik.handleChange}  value={formik.values.prix_peak_min}   label="Prix Peak Min" type="number" placeholder="Entrer Prix Peak Min"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_peak_min)} />
                                                <ErrorMessage name="prix_peak_min" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Peak Moy 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_peak_moy"  onChange={formik.handleChange}  value={formik.values.prix_peak_moy}   label="Prix Peak Moy" type="number" placeholder="Entrer Prix Peak Moy"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.prix_peak_moy)} />
                                                <ErrorMessage name="prix_peak_moy" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Date *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="date" value={formik.values.date} onChange={formik.handleChange} showButtonBar showTime dateFormat="yy-mm-dd" hourFormat="24"showIcon className={inputClassName(formik?.errors?.date)}        />
                                                <ErrorMessage name="date" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Soumettre" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
SpotelectriciteAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'spotelectricite',
	apiPath: 'spotelectricite/add',
	routeName: 'spotelectriciteadd',
	submitButtonLabel: "Soumettre",
	formValidationError: "Le formulaire est invalide",
	formValidationMsg: "Veuillez remplir le formulaire",
	msgTitle: "Créer un enregistrement",
	msgAfterSave: "Élément ajouté avec succès",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default SpotelectriciteAddPage;
