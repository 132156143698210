import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import * as yup from 'yup';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataSource } from 'components/DataSource';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import { TabView, TabPanel } from 'primereact/tabview';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';

import usePostForm from 'hooks/usePostForm';
export default function IndexPage() {
		const auth = useAuth();
	const app = useApp();
	const [searchParams] = useSearchParams();
	const [pageReady, setPageReady] = useState(true);
	const [rememberUser, setRememberUser] = useState(false);
	const formUrl = "auth/login";
	const formData = {
		username: '',
		password: '',
	}
	const validationSchema = yup.object().shape({
		username: yup.string().required().label(`Username or Email`),
		password: yup.string().required().label(`Password`),
	});
	function afterSubmit(loginData){
		if (loginData.token) {
			auth.login(loginData.token);
			const returnUrl = searchParams.get('redirect') || '/home';
			app.navigate(returnUrl);
		}
		else if(loginData.nextpage){
			app.navigate(loginData.nextpage);
		}
	}
	const form = {
		formUrl, formData, validationSchema, afterSubmit
	}
	const { loading, errorMsg, setErrorMsg, formik } = usePostForm(form);
	return (
		<main id="IndexPage" className="main-page">
<section className="page-section m-0 p-0" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col-12 sm:col-12 lg:col-12 md:col-12 comp-grid" >
            </div>
            <div className="col-12 comp-grid" >
                <div className="card  s">
                    <TabView >
                        <TabPanel header={
                            <div>
                                <Avatar className="mr-2" icon="pi pi-home" />
                                <span>Accueil</span></div>
                                }>
                                <div className="">
                                    <DataSource   apiPath="components_data/index_data_component" firstRecord={true} showLoading={false}>
                                        {
                                        ({ response, loading, reload }) => 
                                        <>
                                        <div >
                                            <>
                                            <script src="https://js.stripe.com/v3/"></script>
                                            <div className="bg-bluegray-900 text-gray-100 p-3 flex justify-between lg:justify-center items-center flex-wrap">
                                                <div className="font-bold mr-8">🔥 Offres Chaudes!</div>
                                                <div className="hidden lg:flex items-center">
                                                    <span className="line-height-3">Libero voluptatum atque exercitationem praesentium provident odit.</span>
                                                </div>
                                                <a className="flex items-center ml-2 mr-8">
                                                    <span className="underline font-bold">En Savoir Plus</span>
                                                </a>
                                                <a className="flex items-center no-underline justify-center rounded-full text-100 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150" style={{ width: '2rem', height: '2rem' }}>
                                                    <i className="pi pi-times"></i>
                                                </a>
                                            </div>
                                            <div className="grid grid-nogutter surface-50 text-800">
                                                <div className="col-12 md:col-6 p-6 text-center md:text-left flex items-center">
                                                    <section>
                                                        <span className="block text-6xl font-bold mb-1">L'énergie plus facile que jamais</span>
                                                        <div className="text-6xl text-primary font-bold mb-3">Vous méritez de voir plus clair</div>
                                                        <p className="mt-0 mb-4 text-700 line-height-3">Découvrez des informations claires et précises sur les marchés de l'énergie et du gaz.</p>
                                                        <Button label="En Savoir Plus" onClick={() => history.push('/demo-en-direct')} type="button" className="mr-3 p-button-raised" />
                                                        <Button label="Démo en Direct" type="button" className="p-button-outlined" />
                                                    </section>
                                                </div>
                                                <div className="col-12 md:col-6 overflow-hidden">
                                                    <img src="https://la1ere.francetvinfo.fr/image/MTdRd6kZQ6RL4k-1CQJWrM9cb3Y/930x620/filters:format(webp)/outremer/2019/10/03/5d962928ab985_bourse-investissement-energies-renouvelables-valeurs-807845.jpg" alt="hero-1" className="md:ml-auto block md:h-full" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
                                                </div>
                                            </div>
                                            <div className="surface-0 p-4">
                                                <div className="text-900 font-bold text-6xl mb-4 text-center">Abonnements E-Market</div>
                                                <div className="text-700 text-xl mb-6 text-center line-height-3">Choisissez le plan qui vous convient le mieux.</div>
                                                <div className="grid">
                                                    {loading && 
                                                    <div className="p-2">
                                                        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                                    </div>
                                                    }
                                                    {response && response.map((plan, index) => (
                                                    <div key={index} className="col-12 lg:col-4">
                                                        <div className="p-3 h-full">
                                                            <div className="shadow-2 p-3 h-full flex flex-col surface-100 px-6" style={{ borderRadius: '6px' }}>
                                                                <div className="text-900 font-medium text-3xl my-3">{plan.nom_offre}</div>
                                                                <div className="text-600">{plan.description}</div>
                                                                <hr className="my-3 mx-0 border-t border-b-0 border-300" />
                                                                <div className="flex items-center">
                                                                    <span className="font-bold text-2xl text-900">{plan.prix_mensuel}</span>
                                                                    <span className="ml-2 font-medium text-600">€/mois</span>
                                                                </div>
                                                                <hr className="my-3 mx-0 border-t border-b-0 border-300" />
                                                                <div>{plan.details}</div>
                                                                <hr className="mb-3 mx-0 border-t border-b-0 border-300 mt-auto" />
                                                                <Button label="S'abonner maintenant" className="p-3 w-full mt-auto" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                            </>
                                        </div>
                                        </>
                                        }
                                    </DataSource>
                                </div>
                            </TabPanel>
                            <TabPanel header={
                                <div>
                                    <Avatar className="mr-2" icon="pi pi-lock" />
                                    <span>Connexion</span></div>
                                    }>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="page-section mb-3" >
            <div className="container-fluid w-full min-h-screen ">
                <div className="grid justify-content-center align-items-center gap-3 min-h-screen">
                    <div className="col-12 sm:col-6 md:col-3   comp-grid" >
                        <Title title="Utilisateur en ligne"  headerClass="p-3 card " titleClass="text-xl font-bold text-primary" subTitleClass="text-500" iconClass="pi pi-user" avatarSize="large" avatarClass="bg-primary"   separator={false} />
                        <div className="card my-3 w-1/2 " >
                            <div >
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="mb-2 p-input-icon-left w-full">
                                        <i className="pi pi-user"></i>
                                        <InputText name="username" id="username" label="Nom d'utilisateur ou email" placeholder="Nom d'utilisateur ou email" className="w-full" value={formik.values.username} onChange={formik.handleChange} required="required" type="text" />
                                    </div>
                                    <div className="mb-2 p-input-icon-left w-full">
                                        <i className="pi pi-lock"></i>
                                        <Password name="password" id="password" value={formik.values.password} onChange={formik.handleChange} label="Mot de passe" inputClassName="w-full" feedback={false} toggleMask className="w-full" placeholder="Mot de passe" required="required" />
                                    </div>
                                    <div className="flex justify-content-between align-items-center my-2">
                                        <div className="field-checkbox">
                                            <Checkbox inputId="rememberme" checked={rememberUser} onChange={e => setRememberUser(e.checked)} />
                                            <label className="text-sm text-500" htmlFor="rememberme">Souviens-toi de moi</label>
                                        </div>
                                        <div>
                                            <Link to="/forgotpassword" className="p-button p-button-danger p-button-text">Réinitialiser le mot de passe</Link>
                                            </div>
                                        </div>
                                        { errorMsg && <Message text={errorMsg} severity="error" /> }
                                        <div className="text-center">
                                            <Button label="S'identifier"  loading={loading} icon="pi pi-lock-open" className="p-button-lg p-button-raised w-full"  type="submit"> 
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="card  sp-2">
                                <div className="grid gap-3 align-items-center justify-content-between">
                                    <div className="col text-500 font-bold text-sm">
                                        Vous n'avez pas de compte ?
                                    </div>
                                    <div className="col-fixed">
                                        <Link to="/register">
                                            <Button icon="pi pi-user" label="Registre" className="p-button-success" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
		</main>
	);
}
