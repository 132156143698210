import { useState } from 'react';
import { Button } from 'primereact/button';

export default function UserPage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="UserPage" className="main-page">
<section className="page-section mb-3 card " >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col-12 comp-grid" >
                <div className="">
                    <> 
                    <script src="https://js.stripe.com/v3/"></script>
                    <div className="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
                        <div className="font-bold mr-8">🔥 Offres Chaudes!</div>
                        <div className="align-items-center hidden lg:flex">
                            <span className="line-height-3">Libero voluptatum atque exercitationem praesentium provident odit.</span>
                        </div>
                        <a className="flex align-items-center ml-2 mr-8">
                            <span className="underline font-bold">En Savoir Plus</span>
                        </a>
                        <a className="flex align-items-center no-underline justify-content-center border-circle text-100 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150" style={{ width: '2rem', height: '2rem' }}>
                            <i className="pi pi-times"></i>
                        </a>
                    </div>
                    <div className="grid grid-nogutter surface-50 text-800">
                        <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center">
                            <section>
                                <span className="block text-6xl font-bold mb-1">L'énergie plus facile que jamais</span>
                                <div className="text-6xl text-primary font-bold mb-3">Vous méritez de voir plus clair</div>
                                <p className="mt-0 mb-4 text-700 line-height-3">Découvrez des informations claires et précises sur les marchés de l'énergie et du gaz.</p>
                                <Button label="En Savoir Plus" type="button" className="mr-3 p-button-raised" />
                                <Button label="Démo en Direct" type="button" className="p-button-outlined" />
                            </section>
                        </div>
                        <div className="col-12 md:col-6 overflow-hidden">
                            <img src="https://la1ere.francetvinfo.fr/image/MTdRd6kZQ6RL4k-1CQJWrM9cb3Y/930x620/filters:format(webp)/outremer/2019/10/03/5d962928ab985_bourse-investissement-energies-renouvelables-valeurs-807845.jpg" alt="hero-1" className="md:ml-auto block md:h-full" style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)' }} />
                        </div>
                    </div>
                    <div className="surface-0 p-4">
                        <div className="text-900 font-bold text-6xl mb-4 text-center">Abonnements E-Market</div>
                        <div className="text-700 text-xl mb-6 text-center line-height-3">Choisissez le plan qui vous convient le mieux.</div>
                        <div className="grid">
                            <div className="col-12 lg:col-4">
                                <div className="p-3 h-full">
                                    <div className="shadow-2 p-3 h-full flex flex-column surface-100 px-6" style={{ borderRadius: '6px' }}>
                                        <div className="text-900 font-medium text-3xl my-3">Basique</div>
                                        <div className="text-600">Description du plan</div>
                                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                        <div className="flex align-items-center">
                                            <span className="font-bold text-2xl text-900">49,99</span>
                                            <span className="ml-2 font-medium text-600">€/mois</span>
                                        </div>
                                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                        <ul className="list-none p-0 m-0 flex-grow-1">
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Espace client</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Suivi des prix du gaz et de l'électricité en temps réel.</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>3 alertes de prix personnalisées.</span>
                                            </li>
                                        </ul>
                                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                                        <Button label="S'abonner maintenant" className="p-3 w-full mt-auto" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 lg:col-4">
                                <div className="p-3 h-full">
                                    <div className="shadow-2 p-3 h-full flex flex-column surface-100 px-6" style={{ borderRadius: '6px' }}>
                                        <div className="text-900 font-medium text-3xl my-3">Premium</div>
                                        <div className="text-600">Description du plan</div>
                                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                        <div className="flex align-items-center">
                                            <span className="font-bold text-2xl text-900">149,99</span>
                                            <span className="ml-2 font-medium text-600">€/mois</span>
                                        </div>
                                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                        <ul className="list-none p-0 m-0 flex-grow-1">
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Espace client</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Toutes les fonctionnalités de l'abonnement Basique.</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Suivi des prix des drivers (charbon, pétrole, CO2).</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Graphiques historiques étendus (3 ans).</span>
                                            </li>
                                        </ul>
                                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                                        <Button label="S'abonner maintenant" className="p-3 w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 lg:col-4">
                                <div className="p-3 h-full">
                                    <div className="shadow-2 p-3 flex flex-column surface-100 px-6" style={{ borderRadius: '6px' }}>
                                        <div className="text-900 font-medium text-3xl my-3">Entreprise</div>
                                        <div className="text-600">Description du plan</div>
                                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                        <div className="flex align-items-center">
                                            <span className="font-bold text-2xl text-900">449,99</span>
                                            <span className="ml-2 font-medium text-600">€/mois</span>
                                        </div>
                                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                                        <ul className="list-none p-0 m-0 flex-grow-1">
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Comparaisons de prix entre différents marchés.</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Exportation de données en format CSV.</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Toutes les fonctionnalités de l'abonnement Premium.</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Suivi des exportations de CO2.</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Accès à des rapports d'analyse détaillés par des experts.</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Alertes avancées (notifications push, SMS).</span>
                                            </li>
                                        </ul>
                                        <script async src="https://js.stripe.com/v3/buy-button.js"></script>
                                        <stripe-buy-button buy-button-id="buy_btn_1PHNV7EDWgz2TvU1rqQlhgQe"
                                        publishable-key="pk_test_51H8tBAEDWgz2TvU1pIsW65canFeZXc3bnJsBwkpbrcqacT6pPRZp3E5kj8zunujmNs46WU3FkhTUh6eH9uzjW3Ig002ZtqqSud">
                                        </stripe-buy-button>
                                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                                        <Button label="Acheter Maintenant" className="p-3 w-full p-button-outlined" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                </div>
            </div>
        </div>
    </div>
</section>
		</main>
	);
}
