import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const NotificationsEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		nom_alerte: yup.string().required().label("Nom Alerte"),
		statut: yup.string().required().label("Statut"),
		type_alerte: yup.string().required().label("Type Alerte"),
		max_seuil_opportunite: yup.number().required().label("Max Seuil Opportunite"),
		min_seuil_opportunite: yup.number().required().label("Min Seuil Opportunite"),
		date_debut: yup.string().required().label("Date Debut"),
		date_fin: yup.string().required().label("Date Fin"),
		email: yup.string().email().required().label("Email"),
		tel: yup.array().nullable().label("Tel"),
		siret: yup.string().required().label("Siret"),
		viewer: yup.string().nullable().label("Viewer"),
		note: yup.string().nullable().label("Note")
	});
	// form default values
	const formDefaultValues = {
		nom_alerte: '', 
		statut: '', 
		type_alerte: '', 
		max_seuil_opportunite: '', 
		min_seuil_opportunite: '', 
		date_debut: new Date(), 
		date_fin: new Date(), 
		email: '', 
		tel: [], 
		siret: '', 
		viewer: '', 
		note: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/notifications`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="NotificationsEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modifier"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <Fieldset legend="Détails d'alerte" toggleable>
                                        <div className="grid">
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Nom Alerte *
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="nom_alerte"  onChange={formik.handleChange}  value={formik.values.nom_alerte}   label="Nom Alerte" type="text" placeholder="Entrer Nom Alerte"        className={inputClassName(formik?.errors?.nom_alerte)} />
                                                        <ErrorMessage name="nom_alerte" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Statut *
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <Dropdown  name="statut"     optionLabel="label" optionValue="value" value={formik.values.statut} onChange={formik.handleChange} options={app.menus.statut} label="Statut"  placeholder="Sélectionnez une valeur"  className={inputClassName(formik?.errors?.statut)}   />
                                                        <ErrorMessage name="statut" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Type Alerte *
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="type_alerte"  onChange={formik.handleChange}  value={formik.values.type_alerte}   label="Type Alerte" type="text" placeholder="Entrer Type Alerte"        className={inputClassName(formik?.errors?.type_alerte)} />
                                                        <ErrorMessage name="type_alerte" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Max Seuil Opportunite *
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="max_seuil_opportunite"  onChange={formik.handleChange}  value={formik.values.max_seuil_opportunite}   label="Max Seuil Opportunite" type="number" placeholder="Entrer Max Seuil Opportunite"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.max_seuil_opportunite)} />
                                                        <ErrorMessage name="max_seuil_opportunite" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Min Seuil Opportunite *
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="min_seuil_opportunite"  onChange={formik.handleChange}  value={formik.values.min_seuil_opportunite}   label="Min Seuil Opportunite" type="number" placeholder="Entrer Min Seuil Opportunite"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.min_seuil_opportunite)} />
                                                        <ErrorMessage name="min_seuil_opportunite" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Date Debut *
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <Calendar name="date_debut" showButtonBar className={inputClassName(formik?.errors?.date_debut)} dateFormat="yy-mm-dd" value={formik.values.date_debut} onChange={formik.handleChange} showIcon        />
                                                        <ErrorMessage name="date_debut" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Date Fin *
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <Calendar name="date_fin" showButtonBar className={inputClassName(formik?.errors?.date_fin)} dateFormat="yy-mm-dd" value={formik.values.date_fin} onChange={formik.handleChange} showIcon        />
                                                        <ErrorMessage name="date_fin" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div></Fieldset>
                                        <Fieldset legend="Identité" toggleable>
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="formgrid grid">
                                                        <div className="col-12 md:col-3">
                                                            Email *
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <InputText name="email"  onChange={formik.handleChange}  value={formik.values.email}   label="Email" type="email" placeholder="Entrer Email"        className={inputClassName(formik?.errors?.email)} />
                                                            <ErrorMessage name="email" component="span" className="p-error" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="formgrid grid">
                                                        <div className="col-12 md:col-3">
                                                            Tel 
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            {
                                                            [].map((option) => {
                                                            return (
                                                            <div key={option.value} className="field-checkbox">
                                                                <Checkbox inputId={option.value} name="tel" value={option.value} onChange={formik.handleChange} checked={formik.values.tel.includes(option.value)} className={inputClassName(formik?.errors?.tel, '')} />
                                                                <label htmlFor={option.value}>{option.label}</label>
                                                            </div>
                                                            )
                                                            })
                                                            }
                                                            <ErrorMessage name="tel" component="span" className="p-error" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="formgrid grid">
                                                        <div className="col-12 md:col-3">
                                                            Siret *
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <InputText name="siret"  onChange={formik.handleChange}  value={formik.values.siret}   label="Siret" type="text" placeholder="Entrer Siret"        className={inputClassName(formik?.errors?.siret)} />
                                                            <ErrorMessage name="siret" component="span" className="p-error" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="formgrid grid">
                                                        <div className="col-12 md:col-3">
                                                            Viewer 
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <InputText name="viewer"  onChange={formik.handleChange}  value={formik.values.viewer}   label="Viewer" type="text" placeholder="Entrer Viewer"        className={inputClassName(formik?.errors?.viewer)} />
                                                            <ErrorMessage name="viewer" component="span" className="p-error" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="formgrid grid">
                                                        <div className="col-12 md:col-3">
                                                            Note 
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <InputTextarea name="note"  className={inputClassName(formik?.errors?.note)}   value={formik.values.note} placeholder="Entrer Note" onChange={formik.handleChange}   >
                                                            </InputTextarea>
                                                            <ErrorMessage name="note" component="span" className="p-error" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></Fieldset>
                                        </div>
                                        { props.showFooter && 
                                        <div className="text-center my-3">
                                            <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Editer" icon="pi pi-send" loading={saving} />
                                        </div>
                                        }
                                    </Form>
                                    );
                                    }
                                    }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
		);
	}
}
NotificationsEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'notifications',
	apiPath: 'notifications/edit',
	routeName: 'notificationsedit',
	submitButtonLabel: "Editer",
	formValidationError: "Le formulaire est invalide",
	formValidationMsg: "Veuillez remplir le formulaire",
	msgTitle: "Mettre à jour l'enregistrement",
	msgAfterSave: "Élément modifié avec succès",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default NotificationsEditPage;
