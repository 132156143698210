import { useState } from 'react';
import { Avatar } from 'primereact/avatar';
import { TabView, TabPanel } from 'primereact/tabview';

export default function NucleairePage() {
	
	const [pageReady, setPageReady] = useState(true);
	const [TabPage445, setTabPage445] = useState('TabPage445Page1');
	return (
		<main id="NucleairePage" className="main-page">
<section className="page-section q-pa-md" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
                <div className="">
                    <div className="card  bg-green-100   shadow-2 p-3 border-1 border-50 border-round text-green-600 nice-shadow-6 relative py-5">
                        <div className="flex align-items-center gap-3">
                            <div style={{width:'auto'}}>
                                <Avatar icon="pi pi-cloud" size="large" className="bg-green-400 text-white " />
                                </div>
                                <div>
                                    <div className="font-medium text-lg  ">Les centrales nuclaires actives en france </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid ">
                <div className="sm:col-12 comp-grid" >
                    <div className="card  s">
                        <TabView >
                            <TabPanel header={
                                <div>
                                    <Avatar className="mr-2" icon="pi pi-map" />
                                    <span>Cartographie des centrales actives</span></div>
                                    }>
                                    <div className="">
                                        <div className="bg-white p-3 card">
                                            <iframe
                                            src="https://opendata.edf.fr/explore/embed/dataset/disponibilite-du-parc-nucleaire-d-edf-sa-present-passe-et-previsionnel/custom/?sort=-date_et_heure_fuseau_horaire_europe_paris&refine.date_et_heure_fuseau_horaire_europe_paris=2024%2F06%2F11&refine.heure_fuseau_horaire_europe_paris=17"
                                            style={{ width: '100%', height: '100vh' }}
                                            frameBorder="0"
                                            referrerPolicy="no-referrer"
                                            title="Electricity Maps"
                                            className="w-full p-4 mt-11 rounded-md h-3/4 "
                                            ></iframe>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header={
                                    <div>
                                        <Avatar className="mr-2" icon="pi pi-table" />
                                        <span>Tableau  nucléaire</span></div>
                                        }>
                                        <div className="">
                                            <div className="bg-white p-3 card">
                                                <iframe
                                                src="https://opendata.edf.fr/explore/embed/dataset/disponibilite-du-parc-nucleaire-d-edf-sa-present-passe-et-previsionnel/table/?sort=-date_et_heure_fuseau_horaire_europe_paris&refine.date_et_heure_fuseau_horaire_europe_paris=2024%2F06%2F11&refine.heure_fuseau_horaire_europe_paris=17"
                                                style={{ width: '100%', height: '100vh' }}
                                                frameBorder="0"
                                                referrerPolicy="no-referrer"
                                                title="Electricity Maps"
                                                className="w-full p-4 mt-11 rounded-md h-3/4 "
                                                ></iframe>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
		</main>
	);
}
