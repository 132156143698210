/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {
    
    
    return {
	navbarTopRight: [
  {
    "to": "/futurgazbase",
    "label": "Futurgazbase",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  }
],
	navbarTopLeft: [
  {
    "to": "/futurgazbase",
    "label": "Futurgazbase",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  }
],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Tableau de bord",
    "icon": "pi pi-microsoft",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/utilisateurs",
    "label": "Utilisateurs",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/spotgaz",
    "label": "Spot de gaz",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/spotelectricite",
    "label": "Spot d'électricité",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/futurelecbase",
    "label": "Futur baseload",
    "icon": "pi pi-chart-line",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/futurelecpeak",
    "label": "Futur Peakload",
    "icon": "pi pi-chart-line",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/petrole",
    "label": "Petrole",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/charbon",
    "label": "Charbon",
    "icon": "pi pi-circle-fill",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/co2",
    "label": "Co²",
    "icon": "pi pi-sun",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/emissions",
    "label": "emissions",
    "icon": "pi pi-cloud-upload",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/nucleaire",
    "label": "nucleaire",
    "icon": "pi pi-star",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/consommation",
    "label": "consommation",
    "icon": "pi pi-percentage",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/articles",
    "label": "Articles",
    "icon": "pi pi-align-left",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/roles",
    "label": "Roles",
    "icon": "pi pi-user-edit",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/notifications",
    "label": "Notifications",
    "icon": "pi pi-volume-up",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/senarios",
    "label": "Senarios",
    "icon": "pi pi-book",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/rapports",
    "label": "Rapports",
    "icon": "pi pi-sitemap",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/gazbase",
    "label": "Gaz naturel",
    "icon": "pi pi-cloud",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/nulcaire",
    "label": "Nulcaire",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/permissions",
    "label": "Permissions",
    "icon": "pi pi-user-edit",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/tarifs",
    "label": "Tarifs",
    "icon": "pi pi-list",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/futurgazbase",
    "label": "Futurgazbase",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  }
],
	statut: [    
{value: "activé", label: "activé"},
	{value: "disactivé", label: "disactivé"}
    ],
	typeEnergie: [    
{value: "Gaz nturel", label: "Gaz nturel"},
	{value: "Electrcité", label: "Electrcité"},
	{value: "Charbon", label: "Charbon"},
	{value: "Co2", label: "Co2"},
	{value: "Pétrole", label: "Pétrole"},
	{value: "Nuclaire", label: "Nuclaire"},
	{value: "Solaire", label: "Solaire"},
	{value: "Hydrolique", label: "Hydrolique"},
	{value: "Biomasse", label: "Biomasse"},
	{value: "Hydrogène", label: "Hydrogène"}
    ],
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
    }
}