import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const NotificationsAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		nom_alerte: yup.string().required().label("Nom Alerte"),
		statut: yup.string().required().label("Statut"),
		type_alerte: yup.string().required().label("Type Alerte"),
		max_seuil_opportunite: yup.number().required().label("Max Seuil Opportunite"),
		min_seuil_opportunite: yup.number().required().label("Min Seuil Opportunite"),
		date_debut: yup.string().required().label("Date Debut"),
		date_fin: yup.string().required().label("Date Fin"),
		email: yup.string().email().required().label("Email"),
		tel: yup.array().nullable().label("Tel"),
		siret: yup.string().required().label("Siret"),
		viewer: yup.string().nullable().label("Viewer"),
		note: yup.string().nullable().label("Note")
	});
	
	//form default values
	const formDefaultValues = {
		nom_alerte: '', 
		statut: '', 
		type_alerte: '', 
		max_seuil_opportunite: '', 
		min_seuil_opportunite: '', 
		date_debut: new Date(), 
		date_fin: new Date(), 
		email: '', 
		tel: [], 
		siret: '', 
		viewer: '', 
		note: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/notifications`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="NotificationsAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Ajouter un nouveau"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <Fieldset legend="Détails d'alerte" toggleable>
                                        <div className="grid">
                                            <div className="col-12">
                                                Nom Alerte *
                                                <div> 
                                                    <InputText name="nom_alerte"  onChange={formik.handleChange}  value={formik.values.nom_alerte}   label="Nom Alerte" type="text" placeholder="Entrer Nom Alerte"        className={inputClassName(formik?.errors?.nom_alerte)} />
                                                    <ErrorMessage name="nom_alerte" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                Statut *
                                                <div> 
                                                    <Dropdown  name="statut"     optionLabel="label" optionValue="value" value={formik.values.statut} onChange={formik.handleChange} options={app.menus.statut} label="Statut"  placeholder="Sélectionnez une valeur"  className={inputClassName(formik?.errors?.statut)}   />
                                                    <ErrorMessage name="statut" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                Type Alerte *
                                                <div> 
                                                    <InputText name="type_alerte"  onChange={formik.handleChange}  value={formik.values.type_alerte}   label="Type Alerte" type="text" placeholder="Entrer Type Alerte"        className={inputClassName(formik?.errors?.type_alerte)} />
                                                    <ErrorMessage name="type_alerte" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                Max Seuil Opportunite *
                                                <div> 
                                                    <InputText name="max_seuil_opportunite"  onChange={formik.handleChange}  value={formik.values.max_seuil_opportunite}   label="Max Seuil Opportunite" type="number" placeholder="Entrer Max Seuil Opportunite"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.max_seuil_opportunite)} />
                                                    <ErrorMessage name="max_seuil_opportunite" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                Min Seuil Opportunite *
                                                <div> 
                                                    <InputText name="min_seuil_opportunite"  onChange={formik.handleChange}  value={formik.values.min_seuil_opportunite}   label="Min Seuil Opportunite" type="number" placeholder="Entrer Min Seuil Opportunite"  min={0}  step="0.1"    className={inputClassName(formik?.errors?.min_seuil_opportunite)} />
                                                    <ErrorMessage name="min_seuil_opportunite" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                Date Debut *
                                                <div> 
                                                    <Calendar name="date_debut" showButtonBar className={inputClassName(formik?.errors?.date_debut)} dateFormat="yy-mm-dd" value={formik.values.date_debut} onChange={formik.handleChange} showIcon        />
                                                    <ErrorMessage name="date_debut" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                Date Fin *
                                                <div> 
                                                    <Calendar name="date_fin" showButtonBar className={inputClassName(formik?.errors?.date_fin)} dateFormat="yy-mm-dd" value={formik.values.date_fin} onChange={formik.handleChange} showIcon        />
                                                    <ErrorMessage name="date_fin" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div></Fieldset>
                                        <Fieldset legend="Identité" toggleable>
                                            <div className="grid">
                                                <div className="col-12">
                                                    Email *
                                                    <div> 
                                                        <InputText name="email"  onChange={formik.handleChange}  value={formik.values.email}   label="Email" type="email" placeholder="Entrer Email"        className={inputClassName(formik?.errors?.email)} />
                                                        <ErrorMessage name="email" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    Tel 
                                                    <div> 
                                                        {
                                                        [].map((option) => {
                                                        return (
                                                        <div key={option.value} className="field-checkbox">
                                                            <Checkbox inputId={option.value} name="tel" value={option.value} onChange={formik.handleChange} checked={formik.values.tel.includes(option.value)} className={inputClassName(formik?.errors?.tel, '')} />
                                                            <label htmlFor={option.value}>{option.label}</label>
                                                        </div>
                                                        )
                                                        })
                                                        }
                                                        <ErrorMessage name="tel" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    Siret *
                                                    <div> 
                                                        <InputText name="siret"  onChange={formik.handleChange}  value={formik.values.siret}   label="Siret" type="text" placeholder="Entrer Siret"        className={inputClassName(formik?.errors?.siret)} />
                                                        <ErrorMessage name="siret" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    Viewer 
                                                    <div> 
                                                        <InputText name="viewer"  onChange={formik.handleChange}  value={formik.values.viewer}   label="Viewer" type="text" placeholder="Entrer Viewer"        className={inputClassName(formik?.errors?.viewer)} />
                                                        <ErrorMessage name="viewer" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    Note 
                                                    <div> 
                                                        <InputTextarea name="note"  className={inputClassName(formik?.errors?.note)}   value={formik.values.note} placeholder="Entrer Note" onChange={formik.handleChange}   >
                                                        </InputTextarea>
                                                        <ErrorMessage name="note" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div></Fieldset>
                                        </div>
                                        { props.showFooter && 
                                        <div className="text-center my-3">
                                            <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Soumettre" icon="pi pi-send" loading={saving} />
                                        </div>
                                        }
                                    </Form>
                                    </>
                                    }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
		);
	}
}

//page props and default values
NotificationsAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'notifications',
	apiPath: 'notifications/add',
	routeName: 'notificationsadd',
	submitButtonLabel: "Soumettre",
	formValidationError: "Le formulaire est invalide",
	formValidationMsg: "Veuillez remplir le formulaire",
	msgTitle: "Créer un enregistrement",
	msgAfterSave: "Élément ajouté avec succès",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default NotificationsAddPage;
