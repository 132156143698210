import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const TarifsEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		nom_offre: yup.string().nullable().label("Nom Offre"),
		description: yup.string().nullable().label("Description"),
		details: yup.string().nullable().label("Détails"),
		prix_mensuel: yup.number().nullable().label("Prix Mensuel (€/mois)"),
		url_achat: yup.string().nullable().label("Url Achat")
	});
	// form default values
	const formDefaultValues = {
		nom_offre: '', 
		description: '', 
		details: '', 
		prix_mensuel: '', 
		url_achat: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/tarifs`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="TarifsEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modifier"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nom Offre 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nom_offre"  onChange={formik.handleChange}  value={formik.values.nom_offre}   label="Nom Offre" type="text" placeholder="Entrer Nom Offre"        className={inputClassName(formik?.errors?.nom_offre)} />
                                                <ErrorMessage name="nom_offre" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Description 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="description"  onChange={formik.handleChange}  value={formik.values.description}   label="Description" type="text" placeholder="Entrer Description"        className={inputClassName(formik?.errors?.description)} />
                                                <ErrorMessage name="description" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:col-12 col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Détails 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <div>
                                                    <Editor name="details" onTextChange={(e) => formik.setFieldValue('details', e.htmlValue)} label="Détails"  placeholder="Entrer Détails" value={formik.values.details} style={{height: '300px'}} className={inputClassName(formik?.errors?.details)} />
                                                </div>
                                                <ErrorMessage name="details" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Prix Mensuel (€/mois) 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prix_mensuel"  onChange={formik.handleChange}  value={formik.values.prix_mensuel}   label="Prix Mensuel (€/mois)" type="number" placeholder="0.00"  min={0}  step="0.01"    className={inputClassName(formik?.errors?.prix_mensuel)} />
                                                <ErrorMessage name="prix_mensuel" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Url Achat 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="url_achat"  onChange={formik.handleChange}  value={formik.values.url_achat}   label="Url Achat" type="text" placeholder="Entrer Url Achat"        className={inputClassName(formik?.errors?.url_achat)} />
                                                <ErrorMessage name="url_achat" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Editer" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
TarifsEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'tarifs',
	apiPath: 'tarifs/edit',
	routeName: 'tarifsedit',
	submitButtonLabel: "Editer",
	formValidationError: "Le formulaire est invalide",
	formValidationMsg: "Veuillez remplir le formulaire",
	msgTitle: "Mettre à jour l'enregistrement",
	msgAfterSave: "Élément modifié avec succès",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default TarifsEditPage;
