import { useEffect } from 'react';
import { CanView } from 'components/Can';
import { TabView, TabPanel } from 'primereact/tabview';
import { Title } from 'components/Title';
import PermissionsListPage from 'pages/permissions/List';
import useApp from 'hooks/useApp';
import UtilisateursListPage from 'pages/utilisateurs/List';

const MasterDetailPages = (props) => {
		const app = useApp();
	const { masterRecord, scrollIntoView = true } = props;
	const activeTab = 0;
	function scrollToDetailPage() {
		if (scrollIntoView) {
			const pageElement = document.getElementById('master-detailpage');
			if(pageElement){
				pageElement.scrollIntoView({behavior:'smooth', block:'start'});
			}
		}
	}
	// pass form data from master to detail
	function setDetailPageFormData(){
		const record = masterRecord;
		// set  form data
		const permissionsFormData = { role_id:record?.role_id }
		app.setPageFormData('permissions', permissionsFormData);
		// set  form data
		const utilisateursFormData = { user_role_id:record?.role_id }
		app.setPageFormData('utilisateurs', utilisateursFormData);
	}
	// pass form data from master to detail
	useEffect(() => {
		scrollToDetailPage();
		setDetailPageFormData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [masterRecord]);
	if(masterRecord){
		return (
<div id="master-detailpage">
    <TabView value={activeTab}>
        <TabPanel header={<Title title="Permissions de rôle"  headerClass="p-0" titleClass="text-lg font-bold"  iconClass="pi pi-th-large" avatarSize="small"    separator={false} />}>
            <CanView pagePath="permissions">
                <div className="reset-grid">
                    <PermissionsListPage isSubPage  fieldName="permissions.role_id" fieldValue={masterRecord.role_id} showBreadcrumbs={false} showHeader={true} showFooter={true}>
                    </PermissionsListPage>
                </div>
            </CanView>
        </TabPanel>
        <TabPanel header={<Title title="Rôle de l'utilisateur"  headerClass="p-0" titleClass="text-lg font-bold"  iconClass="pi pi-th-large" avatarSize="small"    separator={false} />}>
            <CanView pagePath="utilisateurs">
                <div className="reset-grid">
                    <UtilisateursListPage isSubPage  fieldName="utilisateurs.user_role_id" fieldValue={masterRecord.role_id} showBreadcrumbs={false} showHeader={true} showFooter={true}>
                    </UtilisateursListPage>
                </div>
            </CanView>
        </TabPanel>
    </TabView>
</div>
		);
	}
}
export default MasterDetailPages;
