import { useState } from 'react';
import { Avatar } from 'primereact/avatar';
import { DataSource } from 'components/DataSource';
import { ProgressBar } from 'primereact/progressbar';
import { Title } from 'components/Title';

export default function ClientPage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="ClientPage" className="main-page">
<section className="page-section q-pa-md card " >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
                <div className="card m-0 p-0">
                    <Title title="Tableau de bord"   titleClass="text-lg font-bold text-primary" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
            <div className="col-12 sm:col-12 lg:col-12 md:col-12 comp-grid" >
                <div className="d-flex flex-row">
                    <div className="">
                        <DataSource   apiPath="components_data/home_data_component" firstRecord={true} showLoading={false}>
                            {
                            ({ response, loading, reload }) => 
                            <>
                            <div >
                                <div className="card  bg-green-100   shadow-2 p-3 border-1 border-50 border-round text-green-600 nice-shadow-6 relative py-5">
                                    <div className="flex align-items-center gap-3">
                                        <div style={{width:'auto'}}>
                                            <Avatar icon="pi pi-cloud" size="large" className="bg-green-400 text-white " />
                                            </div>
                                            <div>
                                                <div className="font-medium text-lg  ">Actualité de gaz naturel</div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading &&
                                    <div className="p-2 my-4">
                                        <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                                    </div>
                                    }
                                    {response &&
                                    <>
                                    <div className="center p-2">
                                        <p className="p-tag text-white  px-4 py-3 my-2">Prix Actuel</p>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix Spot (baseload)</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">24 ↑ | </span>
                                                <span className="text-600">fluctuation de marché </span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix moyen</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-sort text-orange-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">52+ </span>
                                                <span className="text-600">depuis la semaine dérniere</span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix maximum</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-caret-up text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">52+ </span>
                                                <span className="text-600">augementation cette semaine</span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix minmum</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-red-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-caret-down text-red-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">85- </span>
                                                <span className="text-600"> décroissance cette semaine</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="center p-2">
                                        <p className="p-tag text-white px-4 py-3 my-2">Prix futurs</p>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix moyen (forcast)</span>
                                                        <div className="text-900 font-medium text-xl">{ parseFloat(response.prix_cal_01) + parseFloat(response.prix_cal_02) + parseFloat(response.prix_cal_03) } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">24 ↑ | </span>
                                                <span className="text-600">fluctuation de marché </span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix Calendrier 01</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_01 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">85- </span>
                                                <span className="text-600"> décroissance cette semaine</span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix Calendrier 02</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_02 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">85- </span>
                                                <span className="text-600"> décroissance cette semaine</span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix Calendrier 03</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_03 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">85- </span>
                                                <span className="text-600"> décroissance cette semaine</span>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                                </>
                                }
                            </DataSource>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 col-md-4 col-12 comp-grid" >
                    <div className="">
                        <DataSource   apiPath="components_data/home_data_component_2" firstRecord={true} showLoading={false}>
                            {
                            ({ response, loading, reload }) => 
                            <>
                            <div >
                                <div className="card  bg-green-100 shadow-2 p-3 border-1 border-50 border-round text-green-600 nice-shadow-6 relative my-4 py-5">
                                    <div className="flex align-items-center gap-3">
                                        <div style={{width:'auto'}}>
                                            <Avatar icon="pi pi-bolt" size="large" className="bg-primary text-white" />
                                            </div>
                                            <div>
                                                <div className="font-medium text-lg">Actualité d'éléctrcité (baseload)</div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading &&
                                    <div className="p-2 my-4">
                                        <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                                    </div>
                                    }
                                    {response &&
                                    <>
                                    <div className="center p-2">
                                        <p className="p-tag text-white  px-4 py-3 my-2">Prix Actuel</p>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix Spot (baseload) </span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">24 ↑ | </span>
                                                <span className="text-600">fluctuation de marché </span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix moyen</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_01 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-sort text-orange-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">52+ </span>
                                                <span className="text-600">depuis la semaine dérniere</span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix maximum</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_02 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-caret-up text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">52+ </span>
                                                <span className="text-600">augementation cette semaine</span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix minmum</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_03 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-red-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-caret-down text-red-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">85- </span>
                                                <span className="text-600"> décroissance cette semaine</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="center p-2">
                                        <p className="p-tag text-white  px-4 py-3 my-2">Prix Futur</p>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix moyen (forcast)</span>
                                                        <div className="text-900 font-medium text-xl">{ parseFloat(response.prix_cal_01) + parseFloat(response.prix_cal_02) + parseFloat(response.prix_cal_03) } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">24 ↑ | </span>
                                                <span className="text-600">fluctuation de marché </span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix Calendrier 01</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_01 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">85- </span>
                                                <span className="text-600"> décroissance cette semaine</span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix Calendrier 02</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_02 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">85- </span>
                                                <span className="text-600"> décroissance cette semaine</span>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-600 font-medium mb-3">Prix Calendrier 03</span>
                                                        <div className="text-900 font-medium text-xl">{ response.prix_cal_03 } Mkw/€</div>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                        <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <span className="text-green-500 font-medium">85- </span>
                                                <span className="text-600"> décroissance cette semaine</span>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                                </>
                                }
                            </DataSource>
                        </div>
                        <div className="">
                            <DataSource   apiPath="components_data/home_data_component_3" firstRecord={true} showLoading={false}>
                                {
                                ({ response, loading, reload }) => 
                                <>
                                <div >
                                    <div className="card  bg-green-100 shadow-2 p-3 border-1 border-50 border-round text-green-600 nice-shadow-6 relative my-4 py-5">
                                        <div className="flex align-items-center gap-3">
                                            <div style={{width:'auto'}}>
                                                <Avatar icon="pi pi-bolt" size="large" className="bg-primary text-white" />
                                                </div>
                                                <div>
                                                    <div className="font-medium text-lg">Actualité d'éléctrcité (Peakload) </div>
                                                </div>
                                            </div>
                                        </div>
                                        {loading &&
                                        <div className="p-2 my-4">
                                            <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                                        </div>
                                        }
                                        {response &&
                                        <>
                                        <div className="center p-2">
                                            <p className="p-tag text-white  px-4 py-3 my-2">Prix Actuel</p>
                                        </div>
                                        <div className="grid">
                                            <div className="col-12 md:col-6 lg:col-3">
                                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                    <div className="flex justify-content-between mb-3">
                                                        <div>
                                                            <span className="block text-600 font-medium mb-3">Prix Spot (peakload)</span>
                                                            <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                            <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                        </div>
                                                    </div>
                                                    <span className="text-green-500 font-medium">24 ↑ | </span>
                                                    <span className="text-600">fluctuation de marché </span>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6 lg:col-3">
                                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                    <div className="flex justify-content-between mb-3">
                                                        <div>
                                                            <span className="block text-600 font-medium mb-3">Prix moyen</span>
                                                            <div className="text-900 font-medium text-xl">{ response.prix_cal_01 } Mkw/€</div>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                            <i className="pi pi-sort text-orange-500 text-xl"></i>
                                                        </div>
                                                    </div>
                                                    <span className="text-green-500 font-medium">52+ </span>
                                                    <span className="text-600">depuis la semaine dérniere</span>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6 lg:col-3">
                                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                    <div className="flex justify-content-between mb-3">
                                                        <div>
                                                            <span className="block text-600 font-medium mb-3">Prix maximum</span>
                                                            <div className="text-900 font-medium text-xl">{ response.prix_cal_02 } Mkw/€</div>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                            <i className="pi pi-caret-up text-green-500 text-xl"></i>
                                                        </div>
                                                    </div>
                                                    <span className="text-green-500 font-medium">52+ </span>
                                                    <span className="text-600">augementation cette semaine</span>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6 lg:col-3">
                                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                    <div className="flex justify-content-between mb-3">
                                                        <div>
                                                            <span className="block text-600 font-medium mb-3">Prix minmum</span>
                                                            <div className="text-900 font-medium text-xl">{ response.prix_cal_03 } Mkw/€</div>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center bg-red-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                            <i className="pi pi-caret-down text-red-500 text-xl"></i>
                                                        </div>
                                                    </div>
                                                    <span className="text-green-500 font-medium">85- </span>
                                                    <span className="text-600"> décroissance cette semaine</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="center p-2">
                                            <p className="p-tag text-white  px-4 py-3 my-2">Prix Futurs</p>
                                        </div>
                                        <div className="grid">
                                            <div className="col-12 md:col-6 lg:col-3">
                                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                    <div className="flex justify-content-between mb-3">
                                                        <div>
                                                            <span className="block text-600 font-medium mb-3">Prix moyen (forcast)</span>
                                                            <div className="text-900 font-medium text-xl">{ parseFloat(response.prix_cal_01) + parseFloat(response.prix_cal_02) + parseFloat(response.prix_cal_03) } Mkw/€</div>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                            <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                        </div>
                                                    </div>
                                                    <span className="text-green-500 font-medium">24 ↑ | </span>
                                                    <span className="text-600">fluctuation de marché </span>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6 lg:col-3">
                                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                    <div className="flex justify-content-between mb-3">
                                                        <div>
                                                            <span className="block text-600 font-medium mb-3">Prix Calendrier 01</span>
                                                            <div className="text-900 font-medium text-xl">{ response.prix_cal_01 } Mkw/€</div>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                            <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                        </div>
                                                    </div>
                                                    <span className="text-green-500 font-medium">85- </span>
                                                    <span className="text-600"> décroissance cette semaine</span>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6 lg:col-3">
                                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                    <div className="flex justify-content-between mb-3">
                                                        <div>
                                                            <span className="block text-600 font-medium mb-3">Prix Calendrier 02</span>
                                                            <div className="text-900 font-medium text-xl">{ response.prix_cal_02 } Mkw/€</div>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                            <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                        </div>
                                                    </div>
                                                    <span className="text-green-500 font-medium">85- </span>
                                                    <span className="text-600"> décroissance cette semaine</span>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6 lg:col-3">
                                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                    <div className="flex justify-content-between mb-3">
                                                        <div>
                                                            <span className="block text-600 font-medium mb-3">Prix Calendrier 03</span>
                                                            <div className="text-900 font-medium text-xl">{ response.prix_cal_03 } Mkw/€</div>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                            <i className="pi pi-dollar text-green-500 text-xl"></i>
                                                        </div>
                                                    </div>
                                                    <span className="text-green-500 font-medium">85- </span>
                                                    <span className="text-600"> décroissance cette semaine</span>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                    </div>
                                    </>
                                    }
                                </DataSource>
                            </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-12 comp-grid" >
                            <div className="">
                                <DataSource   apiPath="components_data/home_data_component_4" firstRecord={true} showLoading={false}>
                                    {
                                    ({ response, loading, reload }) => 
                                    <>
                                    <div >
                                        <div className="card  bg-green-100 shadow-2 p-3 border-1 border-50 border-round text-green-600 nice-shadow-6 relative my-4 py-5">
                                            <div className="flex align-items-center gap-3">
                                                <div style={{width:'auto'}}>
                                                    <Avatar icon="pi pi-bolt" size="large" className="bg-primary text-white" />
                                                    </div>
                                                    <div>
                                                        <div className="font-medium text-lg">Actualité des drivers (Charbon, Pétrole, CO²) </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loading && 
                                            <div className="p-2">
                                                <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                                            </div>
                                            }
                                            {response &&
                                            <>
                                            <div className="center p-2">
                                                <p className="p-tag text-white px-4 py-3 my-2">CO²</p>
                                            </div>
                                            <div className="grid">
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix Spot </span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">24 ↑ | </span>
                                                        <span className="text-600">fluctuation de marché </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix moyen</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_moy } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-sort text-orange-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">52+ </span>
                                                        <span className="text-600">depuis la semaine dérniere</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix maximum</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_max } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-caret-up text-green-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">52+ </span>
                                                        <span className="text-600">augementation cette semaine</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix minmum</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_min } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-red-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-caret-down text-red-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">85- </span>
                                                        <span className="text-600"> décroissance cette semaine</span>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                            }
                                        </div>
                                        </>
                                        }
                                    </DataSource>
                                </div>
                                <div className="">
                                    <DataSource   apiPath="components_data/home_data_component_5" firstRecord={true} showLoading={false}>
                                        {
                                        ({ response, loading, reload }) => 
                                        <>
                                        <div >
                                            {loading && 
                                            <div className="p-2">
                                                <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                                            </div>
                                            }
                                            {response &&
                                            <>
                                            <div className="center p-2">
                                                <p className="p-tag text-white px-4 py-3 my-2">CO²</p>
                                            </div>
                                            <div className="grid">
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix Spot </span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">24 ↑ | </span>
                                                        <span className="text-600">fluctuation de marché </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix moyen</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_moy } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-sort text-orange-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">52+ </span>
                                                        <span className="text-600">depuis la semaine dérniere</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix maximum</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_max } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-caret-up text-green-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">52+ </span>
                                                        <span className="text-600">augementation cette semaine</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix minmum</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_min } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-red-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-caret-down text-red-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">85- </span>
                                                        <span className="text-600"> décroissance cette semaine</span>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                            }
                                        </div>
                                        </>
                                        }
                                    </DataSource>
                                </div>
                                <div className="">
                                    <DataSource   apiPath="components_data/home_data_component_6" firstRecord={true} showLoading={false}>
                                        {
                                        ({ response, loading, reload }) => 
                                        <>
                                        <div >
                                            {loading && 
                                            <div className="p-2">
                                                <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                                            </div>
                                            }
                                            {response &&
                                            <>
                                            <div className="center p-2">
                                                <p className="p-tag text-white px-4 py-3 my-2">CO²</p>
                                            </div>
                                            <div className="grid">
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix Spot </span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_base } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-money-bill text-green-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">24 ↑ | </span>
                                                        <span className="text-600">fluctuation de marché </span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix moyen</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_moy } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-sort text-orange-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">52+ </span>
                                                        <span className="text-600">depuis la semaine dérniere</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix maximum</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_max } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-caret-up text-green-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">52+ </span>
                                                        <span className="text-600">augementation cette semaine</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                        <div className="flex justify-content-between mb-3">
                                                            <div>
                                                                <span className="block text-600 font-medium mb-3">Prix minmum</span>
                                                                <div className="text-900 font-medium text-xl">{ response.prix_min } Mkw/€</div>
                                                            </div>
                                                            <div className="flex align-items-center justify-content-center bg-red-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                                                <i className="pi pi-caret-down text-red-500 text-xl"></i>
                                                            </div>
                                                        </div>
                                                        <span className="text-green-500 font-medium">85- </span>
                                                        <span className="text-600"> décroissance cette semaine</span>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                            }
                                        </div>
                                        </>
                                        }
                                    </DataSource>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
		</main>
	);
}
